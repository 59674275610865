.web-card {
  display: block;
  margin: 0;
  background-color: #edeef0;
  padding: 20px 15px;
  border-radius: 5px;
}

.web-card__image {
  /* display: block; */
  width: 100%;
  max-height: 300px;
  /* max-height: 450px; */
  object-fit: cover;
  object-position: top;
  margin-bottom: 30px;
  /* transition: object-position 0.2s ease-in-out; */
  transition: 0.2s;
  border: 1px solid white;
  border-radius: 5px;
}

.web-card__image:hover {
  opacity: 0.8;
  border: 1px solid #35a6e8;
}

.web-card__title {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 18px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
}

.web-card__subtitle {
  margin: 0 0 2em;
  padding: 0;
  font-size: 14px;
  font-weight: 300;
}

.web-card__footer {
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.web-card__footer-text-link {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  margin-right: 15px;
  transition: 0.2s;
  color: #999;
}

.web-card__footer-text-link:hover {
  opacity: 0.6;
}

.web-card__footer-git-link {
  display: block;
  background-image: url(../../images/social/github.svg);
  width: 24px;
  height: 24px;
  transition: 0.2s;
}

.web-card__footer-git-link:hover {
  opacity: 0.6;
}

@media screen and (max-width: 768px) {
  .web-card {
    padding-left: 20px;
    padding-right: 20px;
  }

  .web-card__image {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 600px) {
  .web-card__image {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 420px) {
  .web-card {
    padding-left: 10px;
    padding-right: 10px;
  }
}
