.skills__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-template-rows: 100px;
  padding: 0;
  list-style-type: none;
}

.skills__item {
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  fill: #aaa;
  transition: 0.2s;
}

.skills__item:hover {
  fill: #ccc;
}

.skills__caption {
  text-align: center;
  margin: 0;
  padding: 5px 0 0;
  font-size: 14px;
  font-weight: 600;
  color: #999;
}

@media screen and (max-width: 550px) {
  .skills__list {
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    grid-template-rows: 80px;
  }
  .skills__caption {
    font-size: 10px;
  }
}
