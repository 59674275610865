@import url(./vendor/fonts/fonts.css);
@import url(./vendor/normalize.css);
body {
  width: 100%;
  font-family: OpenSans, Arial, Helvetica, sans-serif;
  color: #1a1b22;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
