.modal {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  /* visibility: hidden; */
  /* opacity: 0; */
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}
