.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* width: 100vw; */
  margin: 0 auto;
  /* font-family: OpenSans, Arial, Helvetica, sans-serif; */
  /* color: #1a1b22;
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%; */
}
