.filter {
  width: 100%;
}

.filter__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 5px;
  margin: 0 0 15px;
  padding: 0;
}

.filter__item {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  height: 30px;
  padding: 0 20px;
  border-radius: 15px;
  background-color: #eee;
  cursor: pointer;
  transition: all 200ms ease;
}

.filter__item:hover {
  background-color: #1e9ce6;
  color: white;
}

.filter__item_active {
  background-color: #35a6e8;
  color: white;
}

.filter__item:active {
  background-color: #1591da;
}