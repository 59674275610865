@font-face {
  font-family: 'OpenSans';
  src: url(./open-sans/open-sans-v18-latin_cyrillic-300.woff) format('woff'),
    url(./open-sans/open-sans-v18-latin_cyrillic-300.woff2) format('woff2');
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'OpenSans';
  src: url(./open-sans/open-sans-v18-latin_cyrillic-600.woff) format('woff'),
    url(./open-sans/open-sans-v18-latin_cyrillic-600.woff2) format('woff2');
  font-weight: 600;
  font-display: swap;
}
