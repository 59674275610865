.image-cards {
  /* max-width: 1024px; */
  /* margin: 0 auto; */
}

.image-cards__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-auto-rows: calc(1022px / 3);
  gap: 1px;
  margin: 0;
  padding: 0;
}

.image-cards__title {
  margin: 0 -5px 0.5em;
  padding: 0;
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  text-align: right;
  color: #aaa;
}

.image-cards__subtitle {
  text-align: right;
  margin: 0 0 5px;
  color: #777;
  font-size: 16px;
  line-height: 1.5;
}

.image-cards__help-text {
  text-align: right;
  margin: 0 0 30px;
  color: #777;
  font-size: 16px;
  font-style: italic;
  line-height: 1.5;
}
/* 
.image-card {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
} */

/* .image-card:hover .image-card__info {
  opacity: 1;
} */

/* .image-card__info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-bottom: 3em;
  background-color: rgb(57, 60, 65, 0.9);
  opacity: 0;
  transition: 0.2s;
} */

/* .image-card__title {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  text-align: right;
  color: #aaa;
} */

/* .image-card__subtitle {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
  color: #aaa;
} */

/* .image-card__image {
  display: block;
  width: 100%;
  object-fit: cover;
} */

@media screen and (max-width: 1074px) {
  .image-cards__list {
    grid-auto-rows: calc((100vw - 50px) / 3);
  }
}

@media screen and (max-width: 979px) {
  .image-cards__list {
    grid-auto-rows: calc((100vw - 200px) / 2);
  }
}

@media screen and (max-width: 815px) {
  .image-cards__list {
    grid-auto-rows: calc((100vw - 50px) / 2);
  }
}

@media screen and (max-width: 768px) {
  .image-cards__title {
    text-align: center;
  }
  .image-cards__subtitle {
    text-align: center;
  }

  .image-cards__help-text {
    text-align: center;
  }
}

@media screen and (max-width: 665px) {
  .image-cards__list {
    justify-items: center;
  }
}

@media screen and (max-width: 650px) {
  .image-cards__list {
    grid-auto-rows: 500px;
  }
}

@media screen and (max-width: 550px) {
  .image-cards__list {
    grid-auto-rows: calc(100vw - 50px);
  }
}

@media screen and (max-width: 420px) {
  .image-cards__list {
    grid-auto-rows: calc(100vw - 20px);
  }

  .image-cards__title {
    font-size: 24px;
  }

  .image-cards__subtitle {
    font-size: 13px;
    margin-bottom: 0;
  }

  .image-cards__help-text {
    font-size: 13px;
    margin-bottom: 15px;
  }
}
