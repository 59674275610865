.social-block {
  display: flex;
  margin: 0;
  padding: 0;
}

.social-block__item {
  list-style-type: none;
  padding: 0 10px;
}

.social-block__item:last-child {
  padding-right: 0;
}

.social-block__item:first-child {
  padding-left: 0;
}

.social-block__item svg {
  fill: #999;
  transition: 0.2s;
}

.social-block__item:hover svg {
  fill: #bbb;
}

@media screen and (max-width: 550px) {
  .social-block {
    margin-bottom: 10px;
  }
}
