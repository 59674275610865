.welcome {
  /* position: absolute; */
  width: 100vw;
  height: 100vh;
  background: url('../../images/index-page/welcome_1200X1800.jpg') no-repeat
    center/cover;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 37vh 25px 0;
  box-sizing: border-box;
}

.welcome__title {
  margin: 0 0 15px;
  padding: 0;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0.3em;
  color: #edeef0;
  opacity: 0.7;
  text-align: justify;
}

.welcome__subtitle {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.06em;
  color: #edeef0;
  opacity: 0.5;
  text-align: justify;
}

.welcome__footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.social-block {
  display: flex;
  margin: 0;
  padding: 0;
}

.social-block__item {
  list-style-type: none;
  padding: 0 10px;
}

.social-block__item svg {
  fill: #999;
  transition: 0.2s;
}

.social-block__item:hover svg {
  fill: #bbb;
}

.welcome__copyright {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: #edeef0;
  opacity: 0.3;
}

@media screen and (max-width: 550px) {
  .welcome {
    background: url('../../images/index-page/welcome_550X800.jpg') no-repeat
      center/cover;
  }
  .welcome__footer {
    flex-direction: column-reverse;
  }
  .social-block {
    margin-bottom: 10px;
  }
}
