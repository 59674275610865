.popup {
  z-index: 300;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  visibility: hidden;
  opacity: 0;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
}

.popup__image {
  position: absolute;
  max-width: 70%;
  max-height: 90%;
  object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  border: 1px solid #aaa;
  transition: opacity 300ms ease 100ms;
}

.popup__image_hidden {
  opacity: 0;
}

.popup__image_shift_left {
  transition: all 400ms;
  transform: translate(calc(-100% - 40vw), -50%);
  opacity: 0;
}

.popup__image_shift_right {
  transition: all 400ms;
  opacity: 0;
  transform: translate(40vw, -50%);
}

.popup__btn-container {
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
}

.popup__regular-btn {
  position: fixed;
  bottom: 8%;
  left: 50%;
  transform: translate(-50%, 0);
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: #35a6e8;
  color: white;
  color: #eee;
  width: 320px;
  height: 50px;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.2s;
  font-weight: 600;
}

.popup__regular-btn:hover {
  /* background-color: #35a6e8; */
  background-color: #1e9ce6;
}

.popup__regular-btn:active {
  /* background-color: #35a6e8; */
  background-color: #1591da;
}

.popup__description {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
}

.popup__title {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  color: #999;
}

.popup__subtitle {
  margin: 0;
  padding: 0;
  line-height: 1.3;
  font-weight: 300;
  letter-spacing: 0.07em;
  color: #999;
}

.popup__close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: 0.2s;
  z-index: 10;
}

.popup__close-btn:before {
  position: absolute;
  left: 0;
  top: 20px;
  transform: rotate(45deg);
  content: '';
  width: 100%;
  height: 3px;
  background-color: #aaa;
}
.popup__close-btn::after {
  position: absolute;
  left: 0;
  top: 20px;
  transform: rotate(135deg);
  content: '';
  width: 100%;
  height: 3px;
  background-color: #aaa;
}

.popup__close-btn:hover {
  opacity: 0.6;
}

.popup__close-btn:focus {
  outline: none;
}

@media screen and (max-width: 1025px) {
  .popup__image {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
  }

  .popup__description {
    display: none;
  }

  .popup__btn-container {
    display: none;
  }

  .popup__close-btn {
    top: 3px;
    right: 3px;
    width: 30px;
    height: 30px;
  }

  .popup__close-btn:before {
    top: 15px;
    height: 2px;
  }
  .popup__close-btn::after {
    top: 15px;
    height: 2px;
  }
}

@media screen and (max-width: 420px) {
  .popup__container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .popup__title {
    margin-right: 5px;
    font-size: 18px;
  }

  .popup__subtitle {
    font-size: 12px;
  }
}
