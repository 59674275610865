.about {
  padding-top: 80px;
  max-width: 750px;
  margin: 0 auto;
}

.about__top-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 80px;
}

.about__image {
  display: block;
  position: relative;
  top: 30px;
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 100%;
}

.about__text-container {
  padding-left: 100px;
  max-width: 500px;
}

.about__title {
  margin: 0 0 24px;
  font-weight: 300;
  font-size: 30px;
}

.about__subtitle {
  font-size: 16px;
  text-align: center;
  color: #777;
  margin-bottom: 15px;
}

.about__text {
  max-width: 600px;
  margin: 0 0 10px;
  padding: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
}

.about__text-bold {
  font-weight: 600;
  color: rgb(73, 144, 211);
}

@media screen and (max-width: 1024px) {
  .about {
    padding-top: 60px;
    /* padding-left: 25px; */
    /* padding-right: 25px; */
  }

  .about__text-container {
    padding-left: 50px;
  }
}

@media screen and (max-width: 768px) {
  .about__top-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
  }

  .about {
    padding-top: 30px;
  }

  .about__text-container {
    padding-left: 0;
  }

  .about__image {
    top: 0;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 420px) {
  .about {
    padding: 32px 16px;
  }
}
