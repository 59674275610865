.content {
  flex-grow: 2;
}

.content__container {
  position: relative;
  margin-top: 120px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1074px) {
  .content__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 979px) {
  .content__container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media screen and (max-width: 815px) {
  .content__container {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 768px) {
  .content__container {
    margin-top: 80px;
  }
}

@media screen and (max-width: 420px) {
  .content__container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
