.image-card {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.image-card:hover .image-card__info {
  opacity: 1;
}

.image-card__info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-bottom: 3em;
  background-color: rgb(57, 60, 65, 0.9);
  opacity: 0;
  transition: 0.2s;
}

.image-card__title {
  margin: 0 0 0.5em;
  padding: 0;
  font-size: 22px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  text-align: center;
  color: #aaa;
}

.image-card__subtitle {
  padding: 0;
  margin: 0;
  font-size: 18px;
  font-weight: 300;
  text-align: right;
  color: #aaa;
}

.image-card__image {
  display: block;
  object-fit: cover;
  object-position: 0 0;
  width: 100%;
  height: 100%;
}
