.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #edeef0;
  width: 100%;
  border-bottom: 1px solid #fff;
  transition: 0.5s;
}

.header_hidden {
  transform: translateY(-100%);
}

.header_location_index {
  background-color: transparent;
  border-bottom: none;
}

.header__content {
  max-width: 1400px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 20px 25px;
}

.header__title-link {
  text-decoration: none;
  color: inherit;
}

.header__title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.4em;
  z-index: 10;
  transition: 0.2s;
}

.header__title_hidden {
  display: none;
}

.header__nav-bar {
  align-self: flex-end;
}

.nav-bar__links {
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
}

.nav-bar__link-container {
  margin-left: 30px;
}

.nav-bar__link {
  text-decoration: none;
  color: black;
  transition: 0.2s;
}

.nav-bar__link_location_index {
  color: #edeef0af;
}

.nav-bar__link_active {
  color: #35a6e8;
  /* color: #568eae; */
}

.header__title:hover,
.nav-bar__link:hover {
  color: #35a6e8;
}

.nav-bar__link_location_index:hover {
  color: #edeef0;
}

@media screen and (max-width: 768px) {
  .header__content {
    min-height: 20px;
  }

  .header__nav-bar {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #edeef0;
    flex-direction: column;
    padding: 60px 0 0;
  }

  .header__nav-bar_expanded {
    display: flex;
  }

  .nav-bar__links {
    flex-direction: column;
    width: 100%;
  }

  .nav-bar__link-container {
    display: flex;
    align-items: center;
    min-height: 50px;
    width: 100%;
    margin-left: 0;
  }
  .nav-bar__link-container:hover {
    background-color: aquamarine;
  }

  .nav-bar__link {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    width: 100%;
    margin-left: 0;
  }

  .nav-bar__link_active {
    /* font-weight: 600; */
    color: #888;
    /* border-bottom: 1px solid #aaa; */
    /* color: blue; */
  }

  .header__burger {
    display: block;
    position: relative;
    width: 30px;
    height: 20px;
    cursor: pointer;
    z-index: 100;
  }

  .header__burger:before,
  .header__burger:after {
    content: '';
    background-color: black;
    position: absolute;
    width: 100%;
    height: 2px;
    left: auto;
    transition: 0.2s;
  }

  .header__burger span {
    background-color: black;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 9px;
    transition: 0.2s;
  }

  .header__burger_light span {
    background-color: #999;
  }

  .header__burger_light:before,
  .header__burger_light:after {
    background-color: #999;
  }

  .header__burger:before {
    top: 2px;
  }

  .header__burger:after {
    bottom: 2px;
  }

  .header__burger_active span {
    transform: scale(0);
  }

  .header__burger_active:before {
    transform: rotate(45deg);
    top: 9px;
  }

  .header__burger_active:after {
    transform: rotate(-45deg);
    bottom: 9px;
  }
}
