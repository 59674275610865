.modal-nav-btn {
  position: fixed;
  top: 50%;
  transform: translate(0, -50%);
  margin: 0;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: #ddd;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.2s;
}

.modal-nav-btn:hover {
  background-color: #ccc;
}

.modal-nav-btn:active {
  background-color: #bbb;
}

.modal-nav-btn:hover svg path {
  transition: 0.2s;
}

.modal-nav-btn:hover svg path {
  fill: #35a6e8;
}

.modal-nav-btn:active svg path {
  fill: #3bb7ff;
}

.modal-nav-btn_right {
  right: 20px;
}

.modal-nav-btn_left {
  left: 20px;
}

@media screen and (max-width: 1025px) {
  .modal-nav-btn {
    opacity: 0.8;
  }

  .modal-nav-btn:hover {
    background-color: rgba(221, 221, 221, 0.212);
  }

  .modal-nav-btn_right {
    right: -15px;
  }

  .modal-nav-btn_left {
    left: -15px;
  }
}
