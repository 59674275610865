.web-cards {
  max-width: 1024px;
  margin: 0 auto;
}

.web-cards__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 15px;
  margin: 0;
  padding: 0;
}

.web-cards__title {
  margin: 0 -5px 0.5em;
  padding: 0;
  font-size: 28px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 0.18em;
  text-align: right;
  color: #aaa;
}

@media screen and (max-width: 768px) {
  .web-cards__title {
    text-align: center;
  }
}

@media screen and (max-width: 665px) {
  .web-cards {
    max-width: 400px;
  }

  .web-cards__title {
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .web-cards__title {
    font-size: 24px;
  }
}
