.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
  max-width: 1500px;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
}

.footer_hidden {
  display: none;
}

.footer__copyright {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #b6bcbf;
}

.footer__icon-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.footer__icon-link-container {
  margin: 0;
  padding: 0 0 0 14px;
}

.footer__text-link {
  color: #1a1b22;
  text-decoration: none;
  transition: 0.2s;
}

.footer__icon-link {
  transition: 0.2s;
}

.footer__icon-link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 1024px) {
  .footer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media screen and (max-width: 550px) {
  .footer {
    flex-direction: column-reverse;
    align-items: center;
  }
  .social-block {
    margin-bottom: 10px;
  }
}
